import screensSettings from '@/settings/screens.json'
import { useWindowSize } from '@vueuse/core'
import { isMobile } from 'mobile-device-detect'

const { width: windowWidth, height: windowHeight } = useWindowSize()

type ScreensSettingsKeys = keyof typeof screensSettings
type Screens = Record<ScreensSettingsKeys, { size: number, alLeast: boolean }>

const screens = computed(() => {
  return Object.fromEntries(
    Object.entries(screensSettings).map(([screen, size]) => {
      return [screen, { size, alLeast: windowWidth.value >= size }]
    }),
  ) as Screens
})

function nextLoopEvent(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export const utils = {
  windowWidth,
  windowHeight,
  screens,
  isMobile,
  nextLoopEvent,
}
