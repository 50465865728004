<script setup lang="ts">
import SlideImage1 from '@/assets/images/pages/index/sections/home/header_001.webp'
import SlideImage1Preview from '@/assets/images/pages/index/sections/home/header_001_blur.webp'
import SlideImage2 from '@/assets/images/pages/index/sections/home/header_002.webp'
import SlideImage3 from '@/assets/images/pages/index/sections/home/header_003.webp'
import SlideImage5 from '@/assets/images/pages/index/sections/home/header_005.webp'
import SlideImage7 from '@/assets/images/pages/index/sections/home/header_007.webp'

import SlideImage9 from '@/assets/images/pages/index/sections/home/header_009.webp'

const images = [SlideImage1, SlideImage2, SlideImage3, SlideImage5, SlideImage7, SlideImage9]
const imagePreview = SlideImage1Preview
</script>

<template>
  <section
    id="home"
    class="section"
  >
    <div class="height relative h-[600px] big:h-[800px]">
      <parallax-slider-component
        :images="images"
        :preview-image="imagePreview"
        is-top-element
        :scale-height="1.25"
        :translate-y="-40"
      />
      <div class="header-banner">
        <h1>Life is an adventure</h1>
        <h2>GET INTO THE GAME!</h2>
      </div>
    </div>
    <div class="section-head">
      <h2 class="headline">
        We are Gamesture
      </h2>
      <h3 class="teaser">
        PROUD CREATORS OF <b>QUESTLAND, HERO LEGACY, THE GANG, SLASH & ROLL</b> AND <b>FABLE WARS.</b><br>
        A TEAM OF PEOPLE WHO TRULY LOVE GAMING, AND WANT THEIR WORK TO REFLECT THAT.
      </h3>
    </div>
  </section>
</template>

<style scoped lang="postcss">
#home {
  .header-banner {
    width: 100%;
    padding: 0.88889rem 0;
    position: absolute;
    bottom: 6.66667rem;
    z-index: 2;
    background-color: rgba(45, 124, 138, 0.9);
    text-align: center;
    h1 {
      font-size: 1.88889rem;
      font-weight: 400;
      line-height: 1;
      color: #fff;
    }
    h2 {
      font-size: 1.22222rem;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 1;
      color: #c4fcfa;
      letter-spacing: 1px;
    }
  }
}
@media (min-width: theme('screens.mini')) and (max-width: theme('screens.big')) {
  #home {
    .header-banner {
      h1 {
        font-size: 2.66667rem;
      }
      h2 {
        font-size: 1.55556rem;
      }
    }
  }
}
@media (min-width: theme('screens.big')) {
  #home {
    .header-banner {
      bottom: 4.44444rem;

      h1 {
        font-size: 4rem;
      }

      h2 {
        font-size: 2rem;
      }
    }
  }
}
</style>
