<script setup lang="ts">
import Email from '@/assets/images/pages/index/sections/contact/email.svg?raw'
import Marker from '@/assets/images/pages/index/sections/contact/map-marker-solid.svg?raw'
import { UltraLight } from '@/components/pages/index/GoogleMapStyle_UltraLight'
// prod
const mapKey = 'AIzaSyA-srmHx16Ab5BUu2Mc3T8rc7R5vKyMHqc'
// dev
// const mapKey = 'AIzaSyB3dusSKBZziBH9wmNzDyYzPoutakyAmR8'
// add import '@types/google.maps' to types/index.d.ts; example: https://developers.google.com/maps/documentation/javascript/libraries?hl=pl
function getMarkerTooltip(city: string, description: string, street: string, code: string) {
  return `<div class="info-window">
                <header class="flex justify-start items-start">
                  <div class="info-logo"></div>
                  <h3 class="info-headline">
                      ${city}
                      <div class="info-description">${description}</div>
                  </h3>
                </header>
                <address class="address">
                  <div class="address-p1 flex justify-start items-start">
                    <div class="address-marker">
                     ${Marker}
                    </div>
                    <div >
                     ${street}
                     <br>
                      ${code}
                     </div>
                  </div>
                  <div class="flex justify-start items-start">
                      <div class="address-email">
                        ${Email}
                      </div>
                      <div >
                        <a href="mailto:contact@gamesture.com" class="cursor-pointer">contact@gamesture.com</a>
                      </div>
                  </p>
                </address>
                </div>`
}

async function initMapKrakow() {
  const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary

  const position = { lat: 50.072767, lng: 19.945453 }

  const map = new Map(document.getElementById('mapKrakow') as HTMLElement, {
    zoom: 15,
    center: position,
    mapId: 'gsmap',
  })

  const styledMapType = new google.maps.StyledMapType(UltraLight)
  map.mapTypes.set('styled_map', styledMapType)
  map.setMapTypeId('styled_map')

  const marker = new window.google.maps.marker.AdvancedMarkerElement({
    position,
    map,
  })
  // https://developers.google.com/maps/documentation/javascript/examples/infowindow-simple-max
  // https://developers.google.com/maps/documentation/javascript/reference/info-window?hl=pl#InfoWindowOpenOptions
  const content = getMarkerTooltip('Krakow', 'Main office', 'Pawia 23', '31-154 Krakow')

  const infowindow = new window.google.maps.InfoWindow({
    content,
    ariaLabel: 'gm-info-window',
    pixelOffset: new window.google.maps.Size(0, -10),
    minWidth: 320,
    maxWidth: 330,
  })
  infowindow.open({
    anchor: marker,
    map,
  })
}
async function initMapPoznan() {
  const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary
  // eslint-disable-next-line unused-imports/no-unused-vars
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary

  const position = { lat: 52.405623169788974, lng: 16.883387222088633 }

  const map = new Map(document.getElementById('mapPoznan') as HTMLElement, {
    zoom: 15,
    center: position,
    mapId: 'gsmap',
  })

  const styledMapType = new google.maps.StyledMapType(UltraLight)
  map.mapTypes.set('styled_map', styledMapType)
  map.setMapTypeId('styled_map')

  const marker = new window.google.maps.marker.AdvancedMarkerElement({
    position,
    map,
  })

  // https://developers.google.com/maps/documentation/javascript/examples/infowindow-simple-max
  // https://developers.google.com/maps/documentation/javascript/reference/info-window?hl=pl#InfoWindowOpenOptions
  const content = getMarkerTooltip('POZNAN', 'ADMINISTRATION', 'Grodziska 8', '60-363 Poznan')

  const infowindow = new window.google.maps.InfoWindow({
    content,
    ariaLabel: 'gm-info-window',
    pixelOffset: new window.google.maps.Size(0, -10),
    minWidth: 320,
    maxWidth: 330,
  })
  infowindow.open({
    anchor: marker,
    map,
  })
}

function initMap() {
  initMapKrakow()
  initMapPoznan()
}
interface Win extends Window {
  initMap?: () => void
}
function initMapScript() {
  if (!window.google) {
    const win: Win = window
    win.initMap = () => {
      initMap()
    }
    const tag = document.createElement('script')
    tag.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&callback=initMap&v=weekly&loading=async`
    tag.defer = true
    document.head.appendChild(tag)
  } else {
    initMap()
  }
}

onMounted(() => {
  initMapScript()
})
</script>

<template>
  <section
    id="contact"
    class="section"
  >
    <div class="flex flex-col overflow-hidden big:flex-row">
      <div
        id="mapKrakow"
        class="map w-full big:mr-1 big:w-6/12"
      />
      <div
        id="mapPoznan"
        class="map w-full big:ml-1 big:mt-0 big:w-6/12"
      />
    </div>
  </section>
</template>

<style scoped lang="postcss">
.map {
  height: 36.11111rem;
  margin-top: 0.27778rem;
}

@media (min-width: theme('screens.big')) {
  .map {
    height: 36.11111rem;
  }
}
:deep(.map) {
  [aria-label='gm-info-window'] {
    padding: 2.66667rem !important;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0px 5px 31px 1px rgba(0, 0, 0, 0.1);
    button {
      display: none !important;
    }
  }
  header {
    margin-bottom: 30px;
  }
  .info {
    &-logo {
      width: 15px;
      height: 15px;
      background-image: url('@/assets/images/global/logo-green.svg');
      margin-right: 10px;
    }
    &-headline {
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      line-height: 1;
    }
    &-description {
      font-size: 16px;
      font-weight: 300;
      color: #818791;
    }
  }
  .address {
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 1.5;
    color: #818791;
    &-marker {
      font-size: 17px;
      margin-right: 10px;
      margin-top: 4px;
      color: var(--headline-color);
    }
    &-email {
      font-size: 17px;
      margin-right: 10px;
      margin-top: 5px;
      color: var(--headline-color);
    }
    &-p1 {
      margin: 0 0 24px 0;
    }
    a:hover {
      color: var(--headline-color);
    }
  }
}
</style>
